import React,{Component}  from "react"
import { Link } from "gatsby"


import Formlayout from "../components/formlayout.js"
import Image from "../components/image"
import SEO from "../components/seo"
import "../styles/formlayout.sass"

const Intro = () => (
  <>
    <SEO title="Portal" />  
      <Formlayout>
        <div className="intro"  style={{
          height: '100vh'
        }}>
            <div>
            <h1>Welcome to the Live Absolute Assessment Session </h1>
            <h1>Take a deep breath… </h1>
            <h1>Stay Calm…</h1>  
            <h1>Lets’ begin the Assessment</h1> 
          </div>
          <Link to="/questionnaire/">
            <input style={{
              margin: '5% 40% 0% 40%',
              justifyContent: 'space-evenly'
              
            }}  type="submit" value="Begin">
            </input>
          </Link> 
        </div>
      </Formlayout>
  </>
)

export default Intro